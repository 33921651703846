<template lang="pug">
    ul.listagem
        li(v-for="item in contents.content", v-html="item.content")
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-listagem",
    props,
}
</script>

<style lang="stylus" scoped src="./Listagem.styl"></style>
